<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-input-group>
          <b-form-input
            v-model="keyword"
            placeholder="Plaka, Şase, Araç Kodu..."
            @keydown.enter="keywordSearch"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="keywordSearch"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(title)="data">
          <div
            v-if="data.item.company_name"
            class="font-weight-bold"
          >
            {{ data.item.company_name }}
          </div>
          <div
            v-if="data.item.customer_name"
            class="font-weight-bold"
          >
            {{ data.item.customer_name }}
          </div>
          <div class="font-small-3">
            {{ data.item.title }}
          </div>
          <div class="text-primary font-small-2">
            {{ data.item.arackodu }}
          </div>
          <div class="text-warning font-small-2">
            {{ data.item.chasis }}
          </div>
          <div class="text-muted font-small-2">
            {{ data.item.licence_plate }}
          </div>
        </template>
        <template #cell(sdate)="data">
          {{ moment(data.item.sdate).format('DD.MM.YYYY') }} -
          {{ moment(data.item.edate).format('DD.MM.YYYY') }}
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
              @click="setModalData(data.item)"
            >
              <FeatherIcon icon="UploadIcon" />
              Dosya Yükle
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <b-modal
      v-model="uploadModal"
      title="Dosya Yükle"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-list-group>
        <b-list-group-item>
          <div class="text-primary font-weight-bold">
            Sözleşme No
          </div>
          <div>{{ modalData.contract_no }}</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="text-primary font-weight-bold">
            Sözleşme Bilgisi
          </div>
          <div>
            <div
              v-if="modalData.company_name"
              class="font-weight-bold"
            >
              {{ modalData.company_name }}
            </div>
            <div
              v-if="modalData.customer_name"
              class="font-weight-bold"
            >
              {{ modalData.customer_name }}
            </div>
            <div class="font-small-3">
              {{ modalData.title }}
            </div>
            <div class="font-small-2 text-warning">
              {{ modalData.chasis }}
            </div>
            <div class="font-small-3 text-danger">
              {{ moment(modalData.sdate).format('DD.MM.YYYY') }} - {{ moment(modalData.edate).format('DD.MM.YYYY') }}
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="text-primary font-weight-bold">
            Plaka
          </div>
          <div>
            {{ modalData.licence_plate }}
          </div>
        </b-list-group-item>
      </b-list-group>
      <pre-loading
        v-if="uploading"
        class="my-2"
      />
      <template v-else>
        <b-form-group
          class="mt-1"
          label="Dosya"
          label-for="upload_file"
        >
          <b-form-file
            id="upload_file"
            v-model="modalData.upload_file"
            placeholder="Bir dosya seçin veya buraya bırakın..."
            drop-placeholder="Dosyayı buraya bırakın..."
            browse-text="Dosya Seçin"
          />
        </b-form-group>
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="!modalData.upload_file"
            @click="submitForm"
          >
            <FeatherIcon icon="UploadIcon" />
            Dosya Yükle
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BPagination,
  BCardFooter,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
    BCardFooter,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    PreLoading,
  },
  data() {
    return {
      uploadModal: false,
      modalData: {
        id: null,
        contract_no: null,
        sdate: null,
        edate: null,
        company_name: null,
        customer_name: null,
        arackodu: null,
        title: null,
        chasis: null,
        licence_plate: null,
        id_com_rental_stocks: null,
        upload_file: null,
      },
      keyword: null,
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'contract_no',
          label: 'SÖZLEŞME NO',
          thClass: 'width-150 text-nowrap',
        },
        {
          key: 'title',
          label: 'SÖZLEŞME',
        },
        {
          key: 'sdate',
          label: 'SÖZLEŞME TARİHİ',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalDamgaVergisi/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalDamgaVergisi/dataCounts']
    },
    saveData() {
      return this.$store.getters['rentalDamgaVergisi/dataSaveStatus']
    },
    uploading() {
      return this.$store.getters['rentalDamgaVergisi/uploading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    setModalData(data) {
      this.modalData = {
        id: data.id,
        contract_no: data.contract_no,
        sdate: data.sdate,
        edate: data.edate,
        company_name: data.company_name,
        customer_name: data.customer_name,
        arackodu: data.arackodu,
        title: data.title,
        chasis: data.chasis,
        licence_plate: data.licence_plate,
        id_com_rental_stocks: data.id_com_rental_stocks,
        upload_file: null,
      }
      this.uploadModal = true
    },
    keywordSearch() {
      this.dataQuery.or_like = {
        'com_rental_stocks.arackodu': this.keyword,
        'com_rental_stocks.licence_plate': this.keyword,
        'com_rental_stocks.chasis': this.keyword,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('rentalDamgaVergisi/getDataList', this.dataQuery)
    },
    submitForm() {
      this.$store.dispatch('rentalDamgaVergisi/saveData', this.modalData)
        .then(res => {
          if (res) {
            this.dataQuery.or_like = {}
            this.getDataList()
            this.uploadModal = false
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
